const Appearance = require('@mshops-web-core/appearance').default;

const renderDefaultState = {
  shopModel: {
    shop: {},
  },
  currentCategorySelected: null,
  theme: 'simple',
  fiscalData: null,
  socialNetworks: [],
  contact: null,
  appearance: new Appearance({}),
  initial: new Appearance({}),
  fetching: false,
  apiBasePath: '/api',
  device: 'desktop',
  draftMode: false,
  editingComponent: null,
  action: '',
  sender: '',
  marketplaceInfo: {},
  osName: '',
  uuidVersion: null,
  isEshops: false,
  updateDevice: false,
  richTextValueSetted: '',
  currency: {
    decimal_places: 2,
    decimal_separator: ',',
    thousand_separator: '.',
  },
  siteId: 'MLA',
  lowEnd: false,
  siteKey: 'false-site-key',
  restClient: {
    baseURL: '/api',
    timeout: 5000,
  },
  hideModalSubscription: true,
  isHubPreview: false,
  isShowMenuNavigation: false,
  initialSlideCouponCarousel: 0,
};

module.exports = renderDefaultState;
